<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考务管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a" @click="$router.back()">考场安排</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考场详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="searchForm.userName" clearable size="small" placeholder="请输入姓名"></el-input>
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel">手机号:</span>
              <el-input v-model="searchForm.mobile" clearable size="small" placeholder="请输入手机号"></el-input>
            </div>
            <div title="考场" class="searchboxItem ci-full">
              <span class="itemLabel">考场:</span>
              <el-select v-model="searchForm.classroomId" clearable size="small" placeholder="请选择考场">
                <el-option v-for="(item, index) in examinationList" :key="index" :label="item.name"
                  :value="item.classroomId"></el-option>
              </el-select>
            </div>
            <div title="是否补考" class="searchboxItem ci-full">
              <span class="itemLabel">是否补考:</span>
              <el-select
                  v-model="searchForm.purchased"
                  placeholder="是否补考"
                  size="small"
                  clearable
              >
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="exportSeeExaminationRoom">导出</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="generateNo"
              :disabled="tableData.length <= 0 || mode == '10'">生成准考证号</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <p style="font-size: 12px;color: #666;margin-bottom: 10px;">提醒：如果已经生成准考证，再次生成时只查漏补缺，如需重新生成则作废考场，然后再次生成</p>
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100px" />
              <el-table-column label="姓名" align="center" prop="userName" show-overflow-tooltip min-width="160px" />
              <!-- <el-table-column
                label="身份证号"
                align="center"
                prop="occupationName"
                show-overflow-tooltip
                min-width="160px"
              /> -->
              <el-table-column label="手机号" align="center" prop="mobile" show-overflow-tooltip min-width="120px" />
              <el-table-column label="考场" align="center" prop="name" show-overflow-tooltip min-width="260px" />
              <el-table-column label="座位号" align="center" prop="seatNumber" show-overflow-tooltip min-width="160px" />
              <el-table-column label="准考证号" align="center" prop="userCandidateNo" show-overflow-tooltip min-width="120px" />
              <el-table-column label="是否补考" align="center" prop="purchased" show-overflow-tooltip min-width="100px" >
                <template slot-scope="{ row }">
                  {{ row.purchased ? '是' : '否' }}
                </template>
              </el-table-column>
              <el-table-column label="备注" align="center" prop="remarks" show-overflow-tooltip min-width="260px" />
              <el-table-column label="操作" width="100px" align="center" fixed="right">
                <template v-slot="{ row }">
                  <el-button type="text" size="mini" style="padding: 0 5px;" @click="setExaminationHall(row)"
                    :disabled="!row.seatNumber || !row.userCandidateNo">修改</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 修改考场 -->
    <el-dialog title="修改考场" :visible.sync="examinationHallData.dialogVisible" width="30%" center
      :before-close="setExaminationHallNo">
      <el-form ref="examinationHallData" :model="examinationHallData" size="small" :rules="examinationHallDataRules"
        label-width="100px">
        <el-form-item label="学员姓名" prop="userName">
          {{ examinationHallData.userName }}
        </el-form-item>
        <el-form-item label="原考场" prop="name">
          {{ examinationHallData.name }}
        </el-form-item>
        <el-form-item label="座位号" prop="seatNumber">
          {{ examinationHallData.seatNumber }}
        </el-form-item>
        <el-form-item label="选择新考场" prop="classroomId">
          <el-select v-model="examinationHallData.classroomId" clearable placeholder="请选择新考场">
            <el-option v-for="(item, index) in examinationList" :key="index" :label="item.name"
              :value="item.classroomId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择新座位" prop="changeSeatNumber">
          <el-input-number class="changeSeatNumber" v-model="examinationHallData.changeSeatNumber" :precision="0"
            :step="1" :min="1" :max="9999" :controls="false" size="small"></el-input-number>
        </el-form-item>
        <div class="examinationHallDataTs">提醒：若该座位号已有学员使用，保存后将与学员考场信息进行交换,如果已经下载准考证，须重新下载。</div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setExaminationHallNo">取 消</el-button>
        <el-button type="primary" @click="setExaminationHallOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "examinationRoomArrangement_seeExaminationRoom",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      activityId: "", // 考务id(路由传过来的)
      mode: "",
      // 检索数据
      searchForm: {
        userName: "", // 姓名
        mobile: "", // 手机号
        classroomId: "", // 考场
        purchased: "", // 是否补考
      },
      // 修改考场数据
      examinationHallData: {
        dialogVisible: false, // 弹框显示
        userName: "", // 学员姓名
        name: "", // 原考场教室
        seatNumber: "", // 原座位号
        yclassroomUserId: "", // 原室可选座位号id
        classroomId: "", // 考场教室id
        changeSeatNumber: undefined, // 座位号
      },
      // 可选教室列表
      examinationList: [],
      // 页面数据校验
      examinationHallDataRules: {
        classroomId: [
          {
            required: true,
            message: "请选择新考场",
            trigger: "change",
          },
        ],
        changeSeatNumber: [
          {
            required: true,
            message: "请选择新座位",
            trigger: "blur",
          },
        ]
      },
    };
  },
  created() {
    this.activityId = this.$route.query.activityId;
    this.mode = this.$route.query.mode;
    this.getExaminationList();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 生成准考证号
    generateNo() {
      let params = {
        activityId: this.activityId
      }
      this.$post(
        "/biz/examination_affairs/arrangement/generateCandidateNo",
        params,
        3000,
        true,
        6
      )
        .then((res) => {
          this.$message.success('生成准考证号成功！')
          this.getData()
        })
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        activityId: this.$route.query.activityId,
      };
      if (this.searchForm.userName) {
        params.userName = this.searchForm.userName;
      }
      if (this.searchForm.mobile) {
        params.mobile = this.searchForm.mobile;
      }
      if (this.searchForm.classroomId) {
        params.classroomId = this.searchForm.classroomId;
      }
      params.purchased = this.searchForm.purchased;
      this.doFetch(
        {
          url: "/biz/examination_affairs/arrangement/apportion/page",
          params,
          pageNum,
        },
        true,
        6
      );
    },
    // 导出
    exportSeeExaminationRoom() {
      const params = {
        activityId: this.activityId,
      };
      if (this.searchForm.userName) {
        params.userName = this.searchForm.userName;
      }
      if (this.searchForm.mobile) {
        params.mobile = this.searchForm.mobile;
      }
      if (this.searchForm.classroomId) {
        params.classroomId = this.searchForm.classroomId;
      }
      params.purchased = this.searchForm.purchased;
      this.$post(
        "/biz/examination_affairs/arrangement/apportion/export",
        params,
        3000,
        true,
        6
      )
        .then((res) => {
          const arr = [...res.data];
          for (let item of arr) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        })
        .catch(() => {
          return;
        });
    },
    // 修改考场
    setExaminationHall(row) {
      this.examinationHallData.userName = row.userName;
      this.examinationHallData.name = row.name;
      this.examinationHallData.seatNumber = row.seatNumber;
      this.examinationHallData.yclassroomUserId = row.classroomUserId;
      this.examinationHallData.dialogVisible = true;
    },
    // 获取可选考场列表
    getExaminationList() {
      this.$post("/biz/examination_affairs/arrangement/classroom/list", { activityId: this.$route.query.activityId }, 3000, true, 6).then((res) => {
        this.examinationList = res.data;
      });
    },
    // 修改考场确定
    setExaminationHallOk() {
      this.$refs["examinationHallData"].validate((valid) => {
        if (valid) {
          this.$post("/biz/examination_affairs/arrangement/exchange", {
            activityId: this.activityId,
            classroomId: this.examinationHallData.classroomId,
            ownId: this.examinationHallData.yclassroomUserId,
            changeSeatNumber: this.examinationHallData.changeSeatNumber
          }, 3000, true, 6).then((res) => {
            this.getData(this.pageNum);
            this.setExaminationHallNo();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 修改考场取消 
    setExaminationHallNo() {
      this.examinationHallData = this.$options.data().examinationHallData;
      this.$refs["examinationHallData"].clearValidate();
    }
  },
};
</script>
<style lang="less" scoped>
.examinationHallDataTs {
  color: red;
}

.bgc-bv:disabled,
.bgc-bv:disabled:hover {
  //color: #C0C4CC!important;
  cursor: not-allowed;
  background-image: none;
  //background-color: #FFF!important;
  //border-color: #EBEEF5!important;
  color: #8cc5ff !important;
  background-color: #ecf5ff !important;
  border-color: #d9ecff !important;
}

.changeSeatNumber {
  width: 100%;
  /deep/ input {
    text-align: left;
  }
}</style>